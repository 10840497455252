import init, { decode_trie_index } from 'territory_core'
import { protoGet } from './storage'

const core = init();

const searchIndexCache = {}

export const getIndexKey = cfg => `${cfg.backend}/${cfg.bucket||cfg.root_url}/${cfg.trie}`

const decodeFun = (bytes) => {
    const res =  decode_trie_index(bytes)
    return res
}

const loadIndex = (cfg) => {
    if (cfg.trie) {
        const key = getIndexKey(cfg)
        if (!searchIndexCache[key]) {
            searchIndexCache[key] = core
                .then(() => protoGet(cfg, cfg.trie, false, decodeFun))
                .then((index) => {
                    postMessage({ t: 'indexLoaded', key })
                    return index
                })
        }
        return searchIndexCache[key]
    } else {
        throw Error("non-trie index unsupported")
    }
}

let pendingQuery = null;

const query = (cfg, q, key, limit = 10) => {
    loadIndex(cfg)
    .then(idx => {
        const res = idx.search(q, {limit})
        postMessage({t: 'result', cfg, q, key, data: res})
    })
}


const scheduleQuery = (cfg, q, key, limit) => {
    pendingQuery = [cfg,q,key, limit]
    setTimeout(() => {
        if (pendingQuery) {
            let [cfg,q,key, limit] = pendingQuery;
            pendingQuery = null;
            query(cfg, q ,key, limit);
        }
    })
}


onmessage = (ev) => {
    let msg = ev.data;

    switch (msg.t) {
        case 'loadIndex':
            loadIndex(msg.cfg)
            return;

        case 'query':
            scheduleQuery(msg.cfg, msg.q, msg.key, msg.limit)
            return;

        default:
            console.error('unkown message', msg);
            return;
    }
};
