import { useEffect, useContext } from 'react'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

import { UserContext } from '../../contexts/userContext'
import { auth } from '../../firebase'
import { ReactComponent as Logo } from '../../Logo.svg'

import styles from './Login.module.css'


const PASSWORD_LOGIN = process.env.REACT_APP_PASSWORD_LOGIN


export const Login = () => {
    const {
        user,
    } = useContext(UserContext)

    const { state } = useLocation()
    const [searchParams, _setSearchParams] = useSearchParams()

    const redirectUrl = searchParams.get('redirect') || state?.redirect || '/'

    useEffect(() => {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth)
        const signInOptions = [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                signInMethod: PASSWORD_LOGIN ?
                    firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD :
                    firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                requireDisplayName: false
            },
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ];
        ui.start('#render-login-ui', {
            signInSuccessUrl: redirectUrl,
            signInOptions,
            tosUrl: 'https://territory.dev/terms-of-service.html',
            privacyPolicyUrl: 'https://territory.dev/data.html',

        })
    }, [])

    return (
        <div className={styles.page}>
            <div className={styles.title}>
                <Logo />
            </div>
            <div id="render-login-ui"></div>
        </div>
    )
}
