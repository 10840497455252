import { app } from '../firebase'
import { getStorage, ref, getDownloadURL, connectStorageEmulator } from 'firebase/storage'
import { mockData } from './mocks/mockData'

const {
    REACT_APP_USE_FIRESTORE_EMULATOR: USE_FIRESTORE_EMULATOR,
} = process.env

const storageMap = {}

const getStorageByName = (bucketName) => {
    const firebaseApp = app

    if (!storageMap[bucketName]) {
        const storage = getStorage(firebaseApp, `gs://${bucketName}`)
        if (USE_FIRESTORE_EMULATOR) connectStorageEmulator(storage, "127.0.0.1", 9199)
        storageMap[bucketName] = storage
    }

    return storageMap[bucketName]
}

const getMock = (url) =>  new Promise((resolve) => {
    const mockResponse = mockData[url]

    if (!mockResponse) {
        throw new Error(`No mock data for ${url}`)
    }

    console.log(`calling ${url} with mock data:`, mockResponse)
    setTimeout(() => resolve(mockResponse), 1000)
})


export const protoGet = (storageConfig, path, useMock = false, decodeFun = x=>x, bytes = null) => {
    if (useMock) return getMock(path);

    let url;
    switch (storageConfig.backend) {
        case 'firestore':
        case 'firebase':
        case 'firebase+relay':
            url = getDownloadURL(ref(getStorageByName(storageConfig.bucket), path))
            break;

        case 'url':
            url = Promise.resolve(`${storageConfig.root_url}/${path}`)
            break;

        default:
            throw Error(`unknown storage backend: ${storageConfig.backend}`)
    }

    let headers = new Headers()
    headers.append("Accept-encoding", "gzip")
    if (bytes) {
        console.log("bytes", bytes)
        const [s,e] = bytes
        headers.append("Range", `bytes=${s}-${e-1}`)
    }
    return url
        .then(url => fetch(url, {headers}))
        .then(resp => resp.arrayBuffer())
        .then(proto_data => decodeFun(proto_data))
}
