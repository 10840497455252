import { useContext, useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

import { UserContext } from '../contexts/userContext'
import { auth } from '../firebase'
import { UserAvatar } from './UserAvatar'

import styles from './UserMenu.module.css'

export const UserMenu = () => {
    const {
        user,
        setUser,
    } = useContext(UserContext)

    const navigate = useNavigate()

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const wrapperRef = useRef()

    const logout = () => signOut(auth)
    const goToSettings = () => { navigate('/account') }

    const handleBlur = (e) => {
        if (!wrapperRef?.current?.contains(e.target)) {
            setIsDropdownOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleBlur)

        return () => document.removeEventListener('click', handleBlur)
    })

    return (
        <div
            className={classnames(
                styles.wrapper,
                isDropdownOpen ? styles.opened : styles.closed
            )}
            ref={wrapperRef}
            onClick={() => setIsDropdownOpen(isOpen => !isOpen)}
        >
            <UserAvatar url={user.photoURL} />
            {isDropdownOpen && (
                <div
                    className={styles.dropdown}
                >
                    {user?.displayName && (
                        <div className={styles.username}>{user.displayName}</div>
                    )}
                    <div
                        className={classnames(styles.link, 'tAccountSettings')}
                        onClick={goToSettings}
                    >
                        Settings
                    </div>
                    <div
                        className={styles.link}
                        onClick={logout}
                    >
                        Log out
                    </div>
                </div>
            )}
        </div>
    )
}
