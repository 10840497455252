import { useContext, useCallback, useRef, useEffect } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { GraphContext } from '../../contexts/graphContext'

import { Nodes } from './Nodes'
import { Arrows } from './Arrows'
import styles from './Graph.module.css'


const getGraphCenter = (nodes) => {
    if (!nodes || !Object.keys(nodes).length) return { scale: 1, positionX: 0, positionY: 0 }
    const entries = Object.entries(nodes)
    const top = entries.reduce(
        (prev, [id, { top }]) => (!prev || top < prev) ? top : prev,
        null
    )
    const left = entries.reduce(
        (prev, [id, { left }]) => (!prev || left < prev) ? left : prev,
        null,
    )
    const bottom = entries.reduce(
        (prev, [id, { top, height}]) => (!prev || (top + height) > prev) ?  (top + height) : prev,
        null
    )
    const right = entries.reduce(
        (prev, [id, { left, width }]) => (!prev || (left + width) > prev) ? (left + width) : prev,
        null,
    )

    const width = Math.abs(right - left)
    const height = Math.abs(bottom - top)

    const scale = Math.min(
        document.body.clientHeight / (height * 1.1),
        document.body.clientWidth / (width * 1.1),
    )

    return ({
        y: 64 - top * scale,
        x: 64 - left * scale,
        scale,
    })
}


export const Graph = () => {
    const {
        nodes,
        isDisabled,
        isDragging,
        zoomState,
        setZoomState,
        map,
    } = useContext(GraphContext)

    const transformRef = useRef(null)

    const zoomToCenter = useCallback(() => {
        const center = getGraphCenter(nodes)
        const { x, y, scale } = center
        transformRef.current.setTransform(x, y, scale)
    }, [nodes])

    useEffect(() => {
        if (transformRef?.current) {
            zoomToCenter()
        }
    }, [])


    return Object.keys(nodes)?.length
        ? (
            <div
                id={`graph-wrapper-${map.id}`}
                className={styles.graphWrapper}
            >
                <TransformWrapper
                    initialScale={zoomState.scale}
                    initialPositionX={zoomState.positionX * zoomState.scale}
                    initialPositionY={zoomState.positionY * zoomState.scale}
                    minScale={0.01}
                    maxScale={1}
                    disabled={isDragging}
                    wrapperClass={styles.wrapper}
                    limitToBounds={false}
                    ref={transformRef}
                    wheel={{
                        disabled: isDisabled,
                    }}
                    onTransformed={(ref, state) => {
                        setZoomState(state)
                    }}
                    panning={{
                        velocityDisabled: true,
                    }}
                    zoomAnimation={{
                        disabled: true,
                    }}
                >
                    <TransformComponent
                        wrapperStyle={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            margin: 0,
                            overflow: 'visible',
                        }}
                    >
                        <div className={styles.graph} id={`nodes-wrapper-${map.id}`}>
                            <Nodes />
                        </div>
                        <Arrows />
                    </TransformComponent>
                </TransformWrapper>

            </div>
        ) : (
            null
        )
}
