import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { GraphContext } from '../contexts/graphContext'
import classnames from 'classnames'
import { UserMenu } from './UserMenu'
import { ReactComponent as NavbarLogo } from './NavbarLogo.svg'

import styles from './Navbar.module.css'
import { PrimaryTip, PrimaryTipWrapper } from './PrimaryTip'

export const Navbar = ({
    children,
    shareButton,
    routeInfo,
    userMenu,
}) => {
    const graphContext = useContext(GraphContext)
    return (
        <div className={styles.Navbar}>
            <div className={styles.nav}>
                <PrimaryTipWrapper>
                    <Link to="/">
                        <NavbarLogo
                            className={classnames(styles.logo, 'tHome')}
                        />
                    </Link>
                    {graphContext && (Object.keys(graphContext.nodes || {})?.length === 0) &&
                        <PrimaryTip shiftLeft="7px" shiftDown="8px">
                            home
                        </PrimaryTip>}
                </PrimaryTipWrapper>
                {routeInfo || <div />}
            </div>
            {children}
            <div className={styles.rightIcons}>
                {shareButton}
                {userMenu || <UserMenu />}
            </div>
        </div>
    )
}
