import { useNavigate, useSearchParams } from "react-router-dom";

import { Layout } from '../components/Layout'
import { createMap, getRepos, getBranches, getBuilds } from '../api/api'
import { useState } from "react";


export const NewMapByLink = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [createError, setCreateError] = useState(null);
    const navigate = useNavigate()

    const repoId = searchParams.get('repo_id')
    const branch = searchParams.get('branch')
    const buildId = searchParams.get('build_id')
    const ref = searchParams.get('ref')

    createMap({
        repoId,
        branchId: branch,
        buildId,
        public: false,
        display_name: 'New map',
    })
    .then((newMapId) => {
        const state = {
            addNodes: [ ref ],
        }
        navigate(`/maps/${newMapId}`, { state })
    })
    .catch((e) => { setCreateError(true) })

    return <Layout scrollable>
        {createError
            ? <div>Map creation failed, please try again later.</div>
            : <div>Creating map...</div>}
    </Layout>
}
