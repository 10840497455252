import React, { useContext, useState, useEffect } from 'react'

import { Loader } from '../../components/Loader'
import { Layout } from '../../components/Layout'
import { MapsContext } from '../../contexts/mapsContext'

import { MapsList } from './MapsList'
import { CreateMap } from './CreateMap'
import { RemoveMap } from './RemoveMap'
import { CreateMapButton } from './CreateMapButton'
import { RepoCatalog } from './RepoCatalog'

export const Dashboard = () => {
    const {
        mapsList,
        mapsLoading,
        reloadMaps,
    } = useContext(MapsContext)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [mapToRemove, setMapToRemove] = useState(null)
    const [selectedRepo, setSelectedRepo] = useState(null)

    useEffect(() => { reloadMaps() }, [])

    const createMap = (repoId) => {
        setSelectedRepo(repoId)
        setIsModalOpen(true)
    }

    return (
        <Layout scrollable>
                <CreateMapButton setIsModalOpen={setIsModalOpen}></CreateMapButton>
               {mapsLoading
                    ? <Loader />
                    : (mapsList.length == 0)
                        ? null
                        : <MapsList maps={mapsList} setRemoveMap={setMapToRemove} />
                }
                <RepoCatalog createMap={createMap}/>
                {isModalOpen && (
                    <CreateMap
                        closeModal={() => setIsModalOpen(false)}
                        mapsList={mapsList}
                        open={isModalOpen}
                        selectedRepo={selectedRepo}
                        setSelectedRepo={setSelectedRepo}
                    />
                )}
                {mapToRemove ? (
                    <RemoveMap
                        close={() => setMapToRemove(null)}
                        reloadMaps={reloadMaps}
                        mapToRemove={mapToRemove}
                    />
                ) : null}
        </Layout>
    )
}
